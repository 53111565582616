import React, { useState, useEffect } from 'react'
import dynamic from 'next/dynamic'
import { NextImage } from '@/components/Image/NextImage'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { getAutocompleteData } from '@/lib/searchspring'
import { getColorName, isEmpty } from '@/lib/helper'
import { markdownRenderOptions as defaultMarkdownRenderOptions } from '@/constants/markdownRenderOptions'
import { BLOCKS } from '@contentful/rich-text-types'
import styleConfig from '@/styles/style-config'
import { enableModuleHeroPreview } from '@/lib/contentful/module'
import { productListViewEvent, prepareViewListItems } from '@/lib/ga-events'
import { useRouter } from 'next/router'
import useWindowDimensions from '@/hooks/useWindowDimensions'

const DynamicVideoPlayer = dynamic(() => import('@/components/VideoPlayer/SimpleVideoPlayer'), {
  loading: () => null,
  ssr: false,
})

const ProductPopUp = dynamic(
  () => import('@/components/Product/ProductPopUp').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)

const Icon = dynamic(() => import('@/components/Icon/Icon').then((mod) => mod.default), {
  loading: () => null,
  ssr: true,
})

// const CTA = dynamic(() => import('@/components/CTA').then((mod) => mod.default), {
//   loading: () => null,
//   ssr: true,
// })

import CTA from '@/components/CTA'

const ModuleHero = (props) => {
  const router = useRouter()
  // let currentPath = router.asPath;
  // currentPath = currentPath.includes('?') ? currentPath.split('?')[0] : currentPath
  // // PROMOTION ID
  // const promotionId = (process.env.VERCEL_URL || process.env.NEXT_PUBLIC_BASE_URL)+currentPath;

  // //PROMOTION NAME
  // let promotionName
  // if(currentPath == '/') {
  //   promotionName = 'Base | Static Page'
  // } else {
  //   let pageType = pagePaths.find(page => page.path == currentPath)
  //   promotionName = pageType?.type ? pageType?.type : 'Base | Static Page'
  // }
  // console.log(promotionName)
  const { preview } = props
  const [item, setItem] = useState(props)
  const [showProductMenu, setShowProductMenu] = useState(false)
  const [productsList, setProductsList] = useState([])
  const [gaData, setGaData] = useState(null)
  const [contentOrder, setContentOrder] = useState([])
  const [videoError, setVideoError] = useState(false)
  let objectPosition = 'center'
  const getType = (data) => {
    return data?.fields?.type || 'Normal'
  }

  const getAlignment = (data) => {
    return data?.fields?.alignment || 'Bottom Left'
  }

  const getOverlay = (data) => {
    return data?.fields?.overlay || 'Empty'
  }

  const desktopscreen = Number(styleConfig.theme.screens.lg.replace('px', ''))
  const tabletScreen = Number(styleConfig.theme.screens.md.replace('px', ''))
  const windowDimensions = useWindowDimensions()

  let screen = 'desktop'

  if (windowDimensions.width < desktopscreen) {
    if (windowDimensions.width < tabletScreen) {
      screen = 'mobile'
    } else {
      screen = 'tablet'
    }
  }

  const getObjectPosition = (image, focalPoints) => {
    if (image) {
      let x =
        focalPoints?.focalPoint?.focalPoint?.x /
        focalPoints?.image?.fields?.file?.details?.image?.width

      let y =
        focalPoints?.focalPoint?.focalPoint?.y /
        focalPoints?.image?.fields?.file?.details?.image?.height

      objectPosition = x && y ? `${Math.ceil(x * 100)}% ${Math.ceil(y * 100)}%` : 'center'
    }
  }

  const getBackgroundImage = (data, screen = 'desktop') => {
    const backgroundImage = data?.fields?.backgroundImage
    const desktopFocalpoint = backgroundImage?.fields?.desktopFocal?.fields
    const tabletFocalPoint = backgroundImage?.fields?.tabletFocal?.fields
    const mobileFocalPoint = backgroundImage?.fields?.mobileFocal?.fields
    if (screen == 'desktop') {
      let deksktopFocalImage = desktopFocalpoint?.image?.fields?.file?.url
      deksktopFocalImage && getObjectPosition(deksktopFocalImage, desktopFocalpoint)
      return deksktopFocalImage || backgroundImage?.fields?.desktop?.fields?.file?.url || null
    }

    if (screen == 'tablet') {
      let tabletFocalImage = tabletFocalPoint?.image?.fields?.file?.url
      tabletFocalImage && getObjectPosition(tabletFocalImage, tabletFocalPoint)
      return (
        tabletFocalImage ||
        backgroundImage?.fields?.tablet?.fields?.file?.url ||
        backgroundImage?.fields?.desktop?.fields?.file?.url ||
        null
      )
    }

    if (screen == 'mobile') {
      let mobileFocalImage = mobileFocalPoint?.image?.fields?.file?.url
      mobileFocalImage && getObjectPosition(mobileFocalImage, mobileFocalPoint)
      return (
        mobileFocalImage ||
        backgroundImage?.fields?.mobile?.fields?.file?.url ||
        backgroundImage?.fields?.tablet?.fields?.file?.url ||
        backgroundImage?.fields?.desktop?.fields?.file?.url ||
        null
      )
    }
  }

  const getAltText = (data) => {
    const backgroundImage = data?.fields?.backgroundImage

    return (
      backgroundImage?.fields?.mobile?.fields?.description ||
      backgroundImage?.fields?.tablet?.fields?.description ||
      backgroundImage?.fields?.desktop?.fields?.description ||
      backgroundImage?.fields?.altText ||
      ''
    )
  }

  const getTextContentClass = (data) => {
    const alignmentText = getAlignment(data) == 'Center Center' ? 'Center' : getAlignment(data)
    return `hero-${alignmentText?.replace(' ', '-')?.toLowerCase()}-block`
  }

  const getTextColorClass = (data) => {
    return getOverlay(data) == 'Empty' || getOverlay(data) == 'Dark' ? 'text-white' : 'text-black'
  }

  const getTextPositionClass = (data) => {
    return getAlignment(data)?.includes('Left')
      ? 'text-left'
      : getAlignment(data)?.includes('Right')
      ? 'text-right'
      : 'text-center'
  }

  const getPositionClasses = (data) => {
    return getAlignment(data)?.includes('Left')
      ? 'justify-start items-start'
      : getAlignment(data)?.includes('Right')
      ? 'justify-end items-end'
      : 'justify-center items-center'
  }

  // convert new layout to old layout to use existing content
  const heroTypeMap = {
    'Center Left': 'Hero Center Left',
    'Center Center': 'Hero Center',
    'Center Right': 'Hero Center Right',
    'Bottom Left': 'Hero Bottom Left',
    'Bottom Center': 'Hero Bottom Center',
    'Bottom Right': 'Hero Bottom Right',
  }

  // cta style
  const buttonStyle = `
      .desktop-btn {
        padding:16px;
        line-height: 125%;
        margin-top: 10px;
        margin-bottom: 10px;
        min-width:159px;
        text-align: center;
        justify-content: center;
        align-items: center;
        display: flex;
      }
      @media (min-width: ${styleConfig.theme.screens.lg}) {
        .desktop-btn {
          width: 250px;
          height: 56px;
          padding: 20px 0;
          font-size: 14px;
          line-height: 125%;
        }
      }
    `

  const getProductData = async (data) => {
    let productSearchData = []

    await Promise.all(
      data?.fields?.products?.map(async (product) => {
        try {
          const response = await getAutocompleteData(product?.fields?.productId)
          let results = null

          if (response?.results) {
            results = response?.results
          }

          if (results && results.length > 0) {
            let variantCollection = JSON.parse(results[0].ss_variants.replace(/&quot;/g, '"'))
            let variantData = {}
            const foundItem = variantCollection.find((item) => {
              return item.sizes.some((size) => size.sku === product?.fields?.productId)
            })

            variantData = {
              productSlug: `/products/${results[0].url}/${getColorName(foundItem?.color)}`,
              productName: results[0]?.name,
              productImage: foundItem?.image,
              productPrice: foundItem?.price,
              productId: product?.fields?.productId,
            }
            productSearchData.push(variantData)
          }
        } catch (error) {
          console.error('search data error', error)
        }
      })
    )

    if (productSearchData?.length > 0) {
      let newGaProducts = await prepareViewListItems(productSearchData, 'Hero')

      if (newGaProducts?.length > 0) {
        productSearchData = productSearchData?.map((product) => {
          let skuPartials = product?.productId?.split('-')
          let gaProduct = newGaProducts?.find((product) => product?.sku == skuPartials?.[0])

          return {
            ...product,
            gaData: gaProduct || null,
          }
        })

        setGaData(newGaProducts)
      }
    }

    setProductsList(productSearchData)
  }

  const initializeData = async () => {
    try {
      let data = item

      if (preview) {
        const res = await enableModuleHeroPreview(props, preview)
        setItem(res)
        data = res
      }

      if (data?.fields?.products?.length > 0) {
        getProductData(data)
      }
    } catch (error) {}
  }

  useEffect(() => {
    initializeData()
    setContentOrder(getContentOrder(item))
  }, [])

  useEffect(() => {
    if (showProductMenu && gaData) {
      productListViewEvent(gaData)
    }
  }, [showProductMenu])

  const getContentOrder = (item) => {
    const fields = [
      {
        type: 'eyebrow',
        order: item?.fields?.eyebrowOrder ?? null,
        defaultOrder: 1,
      },
      {
        type: 'headline',
        order: item?.fields?.headlineOrder ?? null,
        defaultOrder: 2,
      },
      {
        type: 'bodyText',
        order: item?.fields?.bodyTextOrder ?? null,
        defaultOrder: 3,
      },
      {
        type: 'ctas',
        order: item?.fields?.ctasOrder ?? null,
        defaultOrder: 4,
      },
    ]

    fields.forEach((field) => {
      if (field.order > 4) {
        field.order = null
      }
    })

    const withContentOrder = fields.filter((f) => f.order !== null)
    const withoutContentOrder = fields.filter((f) => f.order === null)

    withContentOrder.sort((a, b) => a.order - b.order)

    withoutContentOrder.sort((a, b) => a.defaultOrder - b.defaultOrder)

    let result = []
    let i = 0

    withContentOrder.forEach((item) => {
      while (result.length < item.order - 1) {
        result.push(withoutContentOrder[i])
        i++
      }
      result.push(item)
    })

    while (i < withoutContentOrder.length) {
      result.push(withoutContentOrder[i])
      i++
    }

    return result
  }

  const renderTag = (type, content, isH1, className, additionalTag = 'p') => {
    if (!content) return null

    return isH1 ? (
      <h1 className={className} key={type}>
        {content}
      </h1>
    ) : (
      React.createElement(additionalTag, { className, key: type }, content)
    )
  }

  const extendedMarkdownRenderOptions = {
    ...defaultMarkdownRenderOptions,
    renderNode: {
      ...defaultMarkdownRenderOptions.renderNode,
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return <h1>{children}</h1>
      },
    },
  }

  useEffect(() => {
    setVideoError(false)
  }, [screen])

  const loadImage = (item, screen) => {
    return (
      <div className={`z-10 bg-[#fff] module_hero_imageBackground-${getOverlay(item).toLowerCase()} ${screen}-image-background`}>
        <NextImage
          src={`https:${getBackgroundImage(item, screen)}`}
          layout="fill"
          alt={getAltText(item)}
          quality={90}
          objectFit="cover"
          objectPosition={objectPosition}
          priority={item?.index == 0}
        />
      </div>
    )
  }

  const getVideoOrImage = (item, screen = 'desktop') => {
    const videoUrl = item?.fields?.video?.fields?.[screen]?.fields?.file?.url
    if (videoUrl && !videoError) {
      return (
        <div className={`z-20 module_hero_imageBackground-${getOverlay(item).toLowerCase()} module_hero_video`}>
          <DynamicVideoPlayer
            url={videoUrl}
            playing={true}
            loop={true}
            volume={0}
            muted={true}
            className="module_hero_video"
            onError={() => setVideoError(true)}
          />
        </div>
      )
    }
    return loadImage(item, screen)
  }

  return (
    <>
      <div
        className={`module-hero ${item?.fields?.spaceBelowContent ? 'pb-15 md:pb-[100px]' : ''}`}
      >
        <div
          className={`module-hero-content relative bg-center bg-cover ${
            getType(item) == 'Full' ? 'full-height' : ''
          } ${getType(item) == 'Inset' ? 'mx-[24px] lg:mx-[80px]' : ''} ${
            getType(item) == 'Short' ? 'short-height' : ''
          }`}
        >
          {getVideoOrImage(item, screen)}
          {/* Text content */}
          <div
            className={`overflow-hidden flex absolute items-center text-center xs:max-w-full md:max-w-[600px] ${getTextContentClass(
              item
            )} ${
              getType(item) == 'Inset'
                ? 'max-w-none xs:max-w-[250px] md:max-w-[600px] max-w-unset'
                : ''
            } ${
              getType(item) == 'Short' && item?.fields?.ctas?.length > 0
                ? getAlignment(item)?.includes('Left')
                  ? 'pr-[35px] md:pr-0'
                  : getAlignment(item)?.includes('Right')
                  ? 'pl-[35px] md:pl-0'
                  : 'px-[35px] md:px-0'
                : ''
            }`}
          >
            <div
              className={`relative ${
                getType(item) === 'Short' ? 'px-[20px] pb-[20px]' : 'px-6'
              } ${getTextColorClass(item)}`}
            >
              {contentOrder.map((content, index) => {
                switch (content.type) {
                  case 'eyebrow':
                    return renderTag(
                      'eyebrow',
                      item?.fields?.eyebrow,
                      item?.fields?.h1Override === 'Eyebrow',
                      `font-bold leading-tight text-xs md:text-sm uppercase ${getTextPositionClass(
                        item
                      )} ${item?.fields?.spaceBelowTextContent ? 'mt-[25px]' : 'my-3'} ${index == 0 ? '!mt-0': ''}`
                    )
                  case 'headline':
                    return renderTag(
                      'headline',
                      item?.fields?.headline,
                      !item?.fields?.h1Override || item?.fields?.h1Override === 'Headline',
                      `uppercase font-bold text-[32px] md:text-[50px] leading-[38px] md:leading-[50px] ${getTextPositionClass(
                        item
                      )} ${item?.fields?.spaceBelowTextContent ? 'mt-[25px]' : 'my-3'} ${index == 0 ? '!mt-0': ''}`
                    )

                  case 'bodyText': {
                    const bodyTextContent = documentToReactComponents(
                      item?.fields?.bodyText,
                      item?.fields?.h1Override === 'Body Text'
                        ? extendedMarkdownRenderOptions
                        : defaultMarkdownRenderOptions
                    )

                    return (
                      !isEmpty(bodyTextContent) &&
                      renderTag(
                        'bodyText',
                        bodyTextContent,
                        false,
                        `body-text ${
                          getType(item) === 'Short'
                            ? 'leading-6 text-sm pt-2'
                            : 'text-sm md:text-md lg:mt-[18px] leading-[26px]'
                        } promo-title-sub ${getTextPositionClass(item)} ${index == 0 ? '!mt-0': ''}`,
                        'div'
                      )
                    )
                  }
                  case 'ctas':
                    return (
                      item?.fields?.ctas?.length > 0 && (
                        <div
                          className={`button-group-wrap flex flex-col lg:flex-row ${
                            getType(item) === 'Short'
                              ? 'mt-[10px] space-y-[20px] lg:space-y-0'
                              : 'mt-[30px]'
                          } ${getPositionClasses(item)} ${index == 0 ? '!mt-0': ''}`}
                          key="ctas"
                        >
                          {item?.fields?.ctas?.map((cta, index) => {
                            if (cta?.fields?.url && cta?.fields?.label) {
                              return (
                                <CTA
                                  className={`btn btn-white leading-normal tracking-widest md:px-[24px] desktop-btn ${
                                    item?.fields?.ctas?.length == 2 && index == 0 ? 'lg:mr-5' : ''
                                  }`}
                                  label={cta?.fields?.label}
                                  inlineStyles={buttonStyle}
                                  openInNewTab={cta?.fields?.openInNewTab}
                                  openVideoPlayer={cta?.fields?.openVideoPlayer}
                                  url={cta?.fields?.url}
                                  background={cta?.fields?.backgroundColor}
                                  moduleProps={props}
                                />
                              )
                            }
                          })}
                        </div>
                      )
                    )
                  default:
                    return null
                }
              })}
            </div>
          </div>
          {/* Products popup */}
          {item?.fields?.products?.length > 0 && (
            <div className={`absolute left-0 text-left w-[100%] bottom-[80px]`}>
              <div className="relative z-10">
                <div
                  className={`absolute bottom-55 bottom-30 p-1 rounded-full circle-section cursor-pointer ${
                    getAlignment(item) === 'Bottom Right' || getAlignment(item) === 'Center Right'
                      ? 'md:left-20 left-4'
                      : 'md:right-20 right-4'
                  }`}
                  onClick={(e) => {
                    setShowProductMenu(true)
                  }}
                  onMouseEnter={() => {
                    setShowProductMenu(true)
                  }}
                >
                  <div
                    className={`${
                      showProductMenu !== null
                        ? showProductMenu
                          ? ''
                          : 'plus-icon-on-show'
                        : 'plus-icon-on-reload'
                    }`}
                  >
                    <Icon
                      className={`cursor-pointer text-white fill-current w-7 h-7`}
                      viewBox={`0 0 36 36`}
                      size={36}
                      icon="plus-circle"
                    />
                  </div>
                  {/* {showProductMenu ? ( */}
                  <ProductPopUp
                    showProductMenu={showProductMenu}
                    setShowProductMenu={setShowProductMenu}
                    products={productsList}
                    heroType={heroTypeMap[getAlignment(item)]}
                    moduleProps={props}
                  />
                  {/* ) : null} */}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <style jsx>{`
        @media (max-width: 767px) {
          .tablet-image-background,
          .desktop-image-background {
            display: none;
          }
        }
        @media (min-width: 768px) and (max-width: 1023px) {
          .desktop-image-background,
          .mobile-image-background {
            display: none;
          }
        }
        @media (min-width: 1024px) {
          .tablet-image-background,
          .mobile-image-background {
            display: none;
          }
        }
        :global(.module_hero_imageBackground-dark::after) {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
          background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.2) 39.06%, rgba(0, 0, 0, 0) 68.23%, rgba(0, 0, 0, 0) 100%),
            linear-gradient(359.88deg, rgba(0, 0, 0, 0.5) 0.35%, rgba(0, 0, 0, 0.2) 32.33%, rgba(0, 0, 0, 0) 78.07%),
            linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) );
        }
        :global(.module_hero_imageBackground-light::after) {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
        }
        :global(.module_hero_imageBackground-empty::after) {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
        }

        :global(.module_hero_video video) {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          -o-object-fit: cover;
          object-fit: cover;
          -o-object-position: center;
          object-position: center;
        }
      `}</style>
    </>
  )
}

export default ModuleHero
